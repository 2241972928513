import React, { useRef, useLayoutEffect } from 'react'
import * as THREE from 'three';

const BoundingBox = ({ size, color }) => {
    const x = size[0] / 2;
    const y = size[1];
    const z = size[2] / 2;

    const points = [
        [-x, 0, -z], 
        [x, 0, -z],
        [x, 0, z], 
        [-x, 0, z],
        [-x, 0, -z],
        [-x, y, -z],
        [x, y, -z],
        [x, 0, -z],
        [x, y, -z],
        [x, y, z],
        [x, 0, z],
        [x, y, z],
        [-x, y, z],
        [-x, 0, z],
        [-x, y, z],
        [-x, y, -z],
        [-x, y, -z],
    ]
  
    const ref = useRef()
    useLayoutEffect(() => {
        ref.current.geometry.setFromPoints(points.map((point) => new THREE.Vector3(...point)))
    }, [size])

    return (
    <line ref={ref}>
        <bufferGeometry />
        <lineBasicMaterial color={color} />
    </line>
    );
}

export default BoundingBox;