import { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  IconButton,
  Tooltip,
  CircularProgress,
} from "@mui/material";

import DraftsIcon from "@mui/icons-material/Drafts";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import DeleteIcon from "@mui/icons-material/Delete";

import { Canvas, useFrame, useLoader } from "@react-three/fiber";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader.js";
import * as THREE from "three";

import request, { requestForm, getRaw, getAWSRaw } from "../utils/request";

import MenuList from "./MenuList";

import testfile from "../obj/fixture_box_.stl";
import { calculateVolume } from "../utils/helper";

import { useAppContext } from "../AppContext";

const Menu = (props) => {
  const {
    client,
    quoterId,
    items,
    item,
    email,
    status,
    setItems,
    setItem,
    updateItems,
    creatingRequest,
    setCreatingRequst,
    uploadId,
    formatCurrency,
  } = useAppContext();

  const [loading, setLoading] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);

  //const item = useLoader(STLLoader, testfile);

  const addModel = async (file) => {
    if (
      file.name.toLowerCase().indexOf(".stl") === -1 &&
      file.name.toLowerCase().indexOf(".stp") === -1 &&
      file.name.toLowerCase().indexOf(".step") === -1
    ) {
      return;
    }

    const item = {
      filename: file.name,
      index: items.length,
      file: file,
    };

    addModel2(item);
  };

  const addModel2 = useCallback(
    (newItem) => {
      if (uploadId === false && !creatingRequest) {
        setCreatingRequst(true);
      }

      newItem._id = getId();
      newItem.process = "";
      newItem.materialCategory = "";
      newItem.material = "";
      newItem.color = client.processes[0].materialCategories[0].colors[0]._id;
      newItem.quantity = 1;
      newItem.infill = 20;
      newItem.quality = 0;
      newItem.processing = [];
      newItem.price = 0;
      newItem.loading = 1;
      newItem.scale = 1;

      if (item) {
        newItem.process = item.process;
        newItem.materialCategory = item.materialCategory;
        newItem.material = item.material;
        newItem.color = item.color;
        newItem.infill = item.infill;
        newItem.quantity = item.quantity;
        newItem.quality = item.quality;
        newItem.processing = item.processing;
      } else {
        newItem.process = client.materialConfigs[0]._id;
        newItem.materialCategory =
          client.materialConfigs[0].materialCategories[0]._id;
        newItem.color =
          client.materialConfigs[0].materialCategories[0].colors[0]._id;
        newItem.material =
          client.materialConfigs[0].materialCategories[0].colors[0].mats[0]._id;
        newItem.infill = client.materialConfigs[0].infillMinimum;
        if (client.materialConfigs[0].qualities.length > 0) {
          newItem.quality = client.materialConfigs[0].qualities[0].name;
        }
      }

      //setItem(newItem);
      setItems((g) => {
        return [...g, newItem];
      });
    },
    [client, item, uploadId, creatingRequest]
  );

  const uploadModel = async (item) => {
    let formdata = new FormData();
    formdata.append("filename", item.name);
    formdata.append("id", item._id);
    formdata.append("index", item.index);
    formdata.append("file", item.file);
    formdata.append("process", item.process);
    formdata.append("materialCategory", item.materialCategory);
    formdata.append("material", item.material);
    formdata.append("color", item.color);
    formdata.append("infill", item.infill);
    formdata.append("quantity", item.quantity);
    formdata.append("quality", item.quality);
    formdata.append("processing", JSON.stringify(item.processing));
    formdata.append("email", email);
    formdata.append("quoterId", quoterId);

    try {
      const json = await requestForm("/request/part/" + uploadId, formdata, {});
      if (json === false) {
        console.log("Unauth access");

        return;
      }
      if (json.r == 1) {
        let data = await getAWSRaw(encodeURIComponent(json.item.s3Key));
        const arrayBuffer = await data.arrayBuffer();
        finishUpload(json, arrayBuffer);
      } else {
        errorUpload(json);
        console.log(json);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const errorUpload = (json) => {
    setItems((items) => {
      const temp = [...items];
      const item = temp.find((item) => item._id === json.id);
      if (!item) return temp;

      item.loading = 0;
      item.message = json.message;
      item.status = 10;
      item.error = true;
      return temp;
    });
  };

  const finishUpload = (json, arrayBuffer) => {
    if(json.item.extension !== ".stl") {
      setItems((items) => {
        const temp = [...items];
        const item = temp.find((item) => item._id === json._id);
        if (!item) return temp;
        
        item._id = json.item._id.toString();
        item.requestId = uploadId;
        item.status = json.item.status;
        item.extension = json.item.extension;
        json.item.message ? (item.message = json.item.message) : (item.message = "");

        if(json.item.status == 1) {
          item.loading = 0;
        }
        else if(json.item.status == 0) {
          checkItem(item);
        }

        return temp;
      });

      return;
    }

    let geometry = null;
    try {
      geometry = new STLLoader().parse(arrayBuffer);
      geometry.sourceType = "stl";
      geometry.sourceFile = json.item.filename;
    }
    catch(error) {
      console.error(error);

      setItems((items) => {
        const temp = [...items];
        const item = temp.find((item) => item._id === json._id);
        if (!item) return temp;

        item.loading = 0;
        item.message = "Failed to parse STL file";
        item.error = true;
        return temp;
      });

      return;
    }    

    setItem((item) => {
      if (!item) return item;
      if (item._id !== json._id) return item;

      item._id = json.item._id.toString();

      return {...item};
    });

    setItems((items) => {
      const temp = [...items];
      const i = temp.find((item) => item._id === json.item._id || item._id === json._id);
      if (!i) return temp;

      i._id = json.item._id.toString();
      i.requestId = uploadId;
      i.status = json.item.status;
      json.item.message ? (i.message = json.item.message) : (i.message = "");
      i.geometry = geometry;


      if(json.item.status == 1) {
        i.loading = 0;
      }
      else if(json.item.status == 0) {
        checkItem(i);
      }

      return temp;
    });
  };

  const checkItem = useCallback(
    (item) => {
      setTimeout(async () => {
        if (!item) return;
        if (item.status == 1) return;
  
        const json = await request("/request/part/"+item.requestId+"/check", { id: item._id }, {});
        if (json.r == 1 && json.status == 1) {
          let geometry = null;

          if(item.extension !== ".stl") {
            try {
              let data = await getAWSRaw(encodeURIComponent(json.s3Key));
              const arrayBuffer = await data.arrayBuffer();
              geometry = new STLLoader().parse(arrayBuffer);
              geometry.sourceType = "stl";
              geometry.sourceFile = item.filename;
            }
            catch(error) {
              console.error(error);

              setItems((items) => {
                const temp = [...items];
                const item = temp.find((item) => item._id === json._id);
                if (!item) return temp;

                item.loading = 0;
                item.message = "Failed to parse STL file";
                item.error = true;
                return temp;
              });

              return;
            }    
          }

          setItem((oldItem) => {
            if (!oldItem) return oldItem;
            if (oldItem._id !== item._id) return oldItem;

            oldItem.price = json.price;
            oldItem.scale = json.scale;
            oldItem.volume = json.volume;
            oldItem.message = "";
            oldItem.status = json.status;
            oldItem.geometry = geometry;

            return {...oldItem};
          });

          setItems((oldItems) => {
            let temp = [...oldItems];
            let i = temp.find((element) => element._id === item._id);
    
            if (!i) return temp;
            i.price = json.price;
            i.scale = json.scale;
            i.volume = json.volume;
            i.message = "";
            i.status = json.status;
            i.geometry = geometry;
            
            i.loading--;
            if (i.loading < 0) i.loading = 0;
            return temp;
          });
        } else if(json.r == 1 && json.status == 0) {
          setItems((oldItems) => {
            let temp = [...oldItems];
            let i = temp.find((element) => element._id === item._id);
            if (!i) return temp;
            i.status = json.status;
            i.message = json.message;
            return temp;
          });
          checkItem(item);
        } else {
          setItems((oldItems) => {
            let temp = [...oldItems];
            let i = temp.find((element) => element._id === item._id);
            if (!i) return temp;
            i.status = json.status;
            i.message = json.message;
            i.error = json.message;
            i.loading--;
            if (i.loading < 0) i.loading = 0;
            return temp;
          });
        }
      }, 2500);
    },
    [items]
  );
        

  const addUpload = () => {
    if (!client) return;
    let input = document.createElement("input");
    input.type = "file";
    input.accept = ".stl,.stp,.step";
    input.multiple = true;
    input.addEventListener("change", async (e) => {
      for (let i = 0; i < e.target.files.length; i++) {
        await addModel(e.target.files[i]);
      }
    });
    input.click();
  };

  useEffect(() => {
    if (!uploadId) return;
    let tp = 0;
    let isLoading = false;

    setItems((oldItems) => {
      oldItems.forEach((element, index) => {
        if (!element.file) return true;

        uploadModel(element);
        delete element.file;
      });

      return oldItems;
    });

    items.forEach((element, index) => {
      tp += element.price;
      if (element.loading > 0) {
        isLoading = true;
      }
    });

    setLoading(isLoading);
    setTotalPrice(tp);
  }, [items, uploadId]);

  const getId = useCallback(() => {
    let id = (
      Math.random().toString(36).substring(2) +
      Math.random().toString(36).substring(2)
    ).substring(0, 20);

    if (items.find((item) => item._id === id)) {
      return getId();
    }

    return "-" + id;
  }, [items]);

  if (!client) return null;

  if (items.length === 0) {
    let logoPath =
      "https://quoter.ai/wp-content/uploads/2023/03/aiquoter.png.webp";
    if (client.companyLogo) {
      logoPath =
        process.env.REACT_APP_S3_PATH +
        quoterId +
        "/uploads/" +
        client.companyLogo;
    }
    return (
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img id="logo" src={logoPath} alt="logo"></img>
        <Button
          variant="contained"
          startIcon={<UploadFileIcon />}
          onClick={addUpload}
          sx={{ mt: 5, lex: "0 0 auto" }}
        >
          { client.uploadButtonLabel || "Upload Model" }
        </Button>
      </Box>
    );
  }

  return (
    <Card
      id="calculator-menu"
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <CardHeader
        sx={{ pb: 1 }}
        title={ client.partBoxLabel || "Models" }
        titleTypographyProps={{ variant: "subtitle1" }}
      ></CardHeader>
      <Box sx={{ position: "absolute", right: 8, top: 8 }}>
        <Tooltip title={ client.uploadButtonLabel || "Upload Model" } placement="left" arrow>
          <IconButton disabled={status > 0} color="primary" onClick={addUpload} component="span">
            <UploadFileIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Divider />
      <Box sx={{ flexGrow: 1, width: "100%", maxWidth: 360, overflow: "auto" }}>
        {items.length > 0 && (
          <nav>
            <MenuList />
          </nav>
        )}
        {items.length === 0 && (
          <Typography sx={{ fontSize: "1.5rem", textAlign: "center", p: 6 }}>
            No models uploaded yet
          </Typography>
        )}
      </Box>
      <Divider />
      <CardActions sx={{ px: 2, height: "36px" }}>
        <Box sx={{ display: "flex", width: "100%", flexWrap: "wrap" }}>
          <Typography
            sx={{
              flexGrow: 1,
              fontSize: "1.2rem",
              display: "flex",
              alignItems: "center",
            }}
          >
            Total price
          </Typography>
          <Typography
            sx={{
              flexGrow: 1,
              fontSize: "1.2rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            {loading && <CircularProgress size="15px" />}
            {!loading && formatCurrency.format(totalPrice)}
          </Typography>
          <Box sx={{ width: "100%" }}>
            <Typography
              sx={{
                flexGrow: 1,
                fontSize: "1.2rem",
                display: "flex",
                alignItems: "center",
                fontSize: "0.8rem",
                lineHeight: "0.2rem",
              }}
            >
              Tax exclude {client.tax}%
            </Typography>
          </Box>
        </Box>
      </CardActions>
    </Card>
  );
};

export default Menu;
