import React, { useState } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    TextField,
    DialogActions,
    Button,
} from "@mui/material";

const EmailModal = ({ client, open, setOpen, setEmail, email }) => {
    const [error, setError] = useState(false);
    const [submitted, setSubmitted] = useState(false);
  
    const handleClose = () => {
      setOpen(true);
    };
  
    const handleEmail = () => {
      if (!email) {
        setError(true);
        return;
      }

        // regex test for email
      const regex = /\S+@\S+\.\S+/;
      if (!regex.test(email)) {
          setError(true);
          return;
      }
  
      if (client.requireEmail) {
        setOpen(true);
        setSubmitted(true);
      }
    };
  
    return (
      <Dialog open={!open}>
        <DialogTitle>Enter your email address</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {client.requireEmail
              ? "Please enter your email address to continue"
              : "Please enter your email address to receive a quote"}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="email"
            label="Email Address"
            type="email"
            fullWidth
            error={error}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setError(false);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEmail}>Continue</Button>
        </DialogActions>
      </Dialog>
    );
  };

  export default EmailModal;