import React from 'react';

const AppContext = React.createContext();

const useAppContext = () => {
    const context = React.useContext(AppContext);
    if (!context) {
        throw new Error(`useAppContext must be used within a AppContext`);
    }
    return context;
}

export default AppContext;

export { useAppContext };