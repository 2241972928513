import * as THREE from 'three';

const calculateVolume = (geometry) => {
    let position = geometry.attributes.position;
    let faces = position.count / 3;
    let sum = 0;
    let p1 = new THREE.Vector3(),
      p2 = new THREE.Vector3(),
      p3 = new THREE.Vector3();
    for (let i = 0; i < faces; i++) {
      p1.fromBufferAttribute(position, i * 3 + 0);
      p2.fromBufferAttribute(position, i * 3 + 1);
      p3.fromBufferAttribute(position, i * 3 + 2);
      sum += signedVolumeOfTriangle(p1, p2, p3);
    }
    
    return round(sum);
};

const calculateBoundingBox = (geometry) => {
    geometry.computeBoundingBox();
    var bBox = geometry.boundingBox;
    
    let bxh = round(bBox.min.x * -1 + bBox.max.x);
    let byh = round(bBox.min.y * -1 + bBox.max.y);
    let bzh = round(bBox.min.z * -1 + bBox.max.z);
    
    return [bxh, byh, bzh];
};

const signedVolumeOfTriangle = (p1, p2, p3) => {
    return p1.dot(p2.cross(p3)) / 6.0;
};

const round = (num) => {
    return Math.round(num * 1000) / 1000;
};

export {
    calculateBoundingBox,
    calculateVolume,
    signedVolumeOfTriangle,
    round
}