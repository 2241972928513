import {useState, useEffect} from 'react';
import {
    Box,
    Button,
    List, 
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Divider,
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Typography,
    IconButton,
    Grid
} from '@mui/material';

import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';

import { useAppContext } from '../AppContext';
import { useTheme } from '@emotion/react';

const MenuList = (props) => {
    const theme = useTheme
    const {
        client,
        quoterId,
        items,
        item,
        setItem,
        deleteItem,
        formatCurrency,
    } = useAppContext();

    return (
        <List sx={{ p: 0 }}>
                {items.map((i, index) => {
                    if(!i) return null;
                    return (
                        <ListItem 
                            key={i._id}
                            disablePadding
                        >
                            <ListItemButton selected={item != null && item._id === i._id} onClick={(e) => {setItem(i);}}>
                                <ListItemIcon sx={{ minWidth: 34 }}>
                                    <InsertDriveFileIcon />
                                </ListItemIcon>
                                <ListItemText sx={{overflow: "hidden", width: "100%" }} primary={(
                                    <>
                                        <Grid container spacing={0}>
                                            <Grid item xs={7}>
                                                <Typography sx={{ overflow: "hidden" }} color={i.status == 10 ? "red" : ""} title={i.filename}noWrap>{i.filename}</Typography>
                                            </Grid>
                                            <Grid item xs={5} sx={{ textAlign: "right" }}>
                                                { (i.loading < 1 && i.status == 10) && (
                                                    <IconButton sx={{ p: 0 }} aria-label="delete" size="small" onClick={() => deleteItem(i)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                )}
                                                <Typography noWrap>
                                                { (i.loading < 1 && i.status == 1) && formatCurrency.format(i.price) }
                                                { i.loading > 0 && <CircularProgress size="15px" /> }
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </>
                                )} />
                                
                            </ListItemButton>
                        </ListItem>
                    );
                })}
        </List>
    );
}

export default MenuList;
