
const RequestException = function(status, msg){
    this.status = status;
    this.msg = msg;
}

const request = async (path, data, rconfig) => {
    rconfig = rconfig || {};
    const settings = {
        method: rconfig.method || 'POST',
        headers: {
            'Content-Type': 'application/json'
        }
    };

    if (data !== false) {
        settings.body = JSON.stringify(data);
    }
    const response = await fetch(process.env.REACT_APP_API_PATH + path, settings);

    if (!response.ok) {
        throw new RequestException(response.status, await response.text());
    }

    const json = await response.json();
    return json;
};

const requestForm = async (path, data, rconfig) => {
    const settings = {
        method: rconfig.method || 'POST',
    };

    if (data !== false) {
        settings.body = data;
    }
    const response = await fetch(process.env.REACT_APP_API_PATH + path, settings);

    if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
    }

    const json = await response.json();
    return json;
};

const getRaw = async (path) => {
    const settings = {
        method: 'GET',
    };

    const response = await fetch(process.env.REACT_APP_API_PATH + path, settings);

    if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
    }

    const raw = await response.text();
    return raw;
};

const getAWSRaw = async (path) => {
    const settings = {
        method: 'GET',
    };

    const response = await fetch(process.env.REACT_APP_S3_PATH + path, settings);

    if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
    }

    const raw = await response.blob();
    return raw;
};

export { requestForm, getRaw, getAWSRaw };

export default request;
